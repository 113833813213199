import React from 'react';
import styled from 'styled-components';
import NotFoundLogo from '../assets/img/notFoundLogo.png';
import Layout from '../components/Layout';
import Button from '../components/Shared/Button';
import H2 from '../components/Shared/H2';
import Section from '../components/Shared/Section';
import variables from '../helpers/variables';

const NotFoundPage = () => (
  <Layout>
    <NoPaddingSection>
      <ScreenRow>
        <ImageContainer>
          <img src={NotFoundLogo} />
          <OOPSText>OOPS! LOOKS LIKE YOU'RE LOST…</OOPSText>
          <Button
            onClick={() => {
              history.back();
            }}
          >
            GO BACK
          </Button>
        </ImageContainer>
      </ScreenRow>
    </NoPaddingSection>
  </Layout>
);

const ImageContainer = styled.div`
  max-width: 45.375rem;
`;
const ScreenRow = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const NoPaddingSection = styled(Section)`
  padding-top: 0;
  margin-top: -4rem;
`;

const OOPSText = styled(H2)`
  ${variables.breakpoints.lg} {
    font-size: 5rem;
  }
`;

export default NotFoundPage;
